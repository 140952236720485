// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contribute-js": () => import("./../src/pages/contribute.js" /* webpackChunkName: "component---src-pages-contribute-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-why-js": () => import("./../src/pages/why.js" /* webpackChunkName: "component---src-pages-why-js" */),
  "component---src-templates-code-js": () => import("./../src/templates/code.js" /* webpackChunkName: "component---src-templates-code-js" */),
  "component---src-templates-provider-js": () => import("./../src/templates/provider.js" /* webpackChunkName: "component---src-templates-provider-js" */),
  "component---src-templates-response-js": () => import("./../src/templates/response.js" /* webpackChunkName: "component---src-templates-response-js" */),
  "component---src-templates-spam-filter-js": () => import("./../src/templates/spamFilter.js" /* webpackChunkName: "component---src-templates-spam-filter-js" */)
}

