module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://smtpfieldmanual.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SMTP Field Manual","short_name":"SMTP Manual","description":"A collection of raw SMTP error responses from major email service providers and spam filters.","start_url":"/","background_color":"#FFCC00","theme_color":"#3B377B","display":"standalone","icon":"src/images/smtpfm-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-507677-43"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
